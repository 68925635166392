import './App.css';

function App() {
    return (
        <div className="App">
            <header className="App-header">
                <h1>Migrating Domain</h1>
                <br></br>
                <h4>
                    All subdomains/sites under pocable.ca are being transferred to roskewich.ca
                    <h6>Use that since I am deleting the old domain when it expires</h6>
                </h4>
            </header>
        </div>
    );
}

export default App;
